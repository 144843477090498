import Button from '@components/atoms/Button';
import {theme} from '@styles/theme';
import Link from 'next/link';
import styled from 'styled-components';

export const MainWrapper = styled.div`
  width: 100%;
`;

export const textStyle = `
font-weight: 400;
font-size: 13px;
line-height: 15px;
letter-spacing: 0.1px;
color: ${theme.colors.darkText};
`;

export const ContentWrapper = styled.div`
  display: flex;
`;

export const DesktopHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const LogoWrapper = styled.div`
  width: 219px;
  height: 15px;
  padding: 22.5px 0;
  cursor: pointer;
  margin-right: 50px;
  position: relative;
  @media (max-width: 1200px) {
    margin-right: 15px;
    width: 190px;
  }
  @media (max-width: 1120px) {
    width: 170px;
    margin-right: 15px;
  }
  @media (max-width: 820px) {
    /* margin-right: 0; */
  }
`;

export const CurrentTown = styled.div`
  ${theme.flexAlignItemsCenter};
  gap: 6px;
  cursor: pointer;
  margin-right: auto;
`;

export const CurrentTownText = styled.div`
  ${textStyle};
  @media (max-width: 900px) {
    display: none;
  }
`;

export const CurrentTownImage = styled.div`
  min-width: 12px;
`;

export const Calls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  button {
    @media (max-width: 1020px) {
      display: none;
    }
  }
`;

export const Numbers = styled.div`
  font-weight: 500;
  display: flex;
  align-items: center;
  margin-right: 40px;
  @media (max-width: 1120px) {
    margin-right: 20px;
  }
`;

export const Number = styled.a`
  ${textStyle}
`;

export const NumberSeparator = styled.div`
  background-color: ${theme.colors.darkText};
  width: 1px;
  height: 20px;
  margin: 0 11px;
`;

export const DesktopHeaderServices = styled.div`
  display: flex;
  align-items: center;
`;

export const Personal = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-right: 40px;
  position: relative;
  &::before, &::after {
    content: '';
    position: absolute;
    left: -50%;
    right: -50%;
    bottom: 0;
    height: 100px;
    cursor: pointer;
  }
  &::after {
    bottom: auto;
    top: 100%;
  }

  @media (max-width: 1120px) {
    margin-right: 20px;
  }

  &:hover {
    color: ${({theme}) => theme.colors.brandGreenV2};
  }
`;

export const PersonalImage = styled.div`
  min-width: 12px;
  display: flex;
  margin-bottom: 1px;
`;

export const PersonalText = styled.div`
  ${textStyle};
  margin-left: 5px;
  cursor: pointer;
  white-space: nowrap;
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: underline;
  @media (max-width: 1200px) {
    max-width: 150px;
  }
  @media (max-width: 769px) {
    max-width: 120px;
  }
`;

export const DesktopHeaderButtons = styled.div`
  margin-right: auto;
  ${theme.flexAlignItemsCenter};
  gap: 20px;
`;

export const DesktopHeaderButton = styled(Link)`
  position: relative;
  cursor: pointer;
`;

export const Quantity = styled.div`
  position: absolute;
  font-size: 11px;
  font-weight: bold;
  top: -8px;
  right: -4px;
`;


export const CallbackButton = styled(Button)`
  margin-right: 40px;
  font-weight: 500;
  padding: 10px 18px;
  @media (max-width: 1200px) {
    margin-right: 20px;
  }
`